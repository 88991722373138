<template>
  <div>
    <vx-card>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="custom-row">
        <vs-col vs-sm="12" vs-lg="8" class="custom-col">
          <vs-input
            icon-after="true"
            label-placeholder="Search"
            icon="icon icon-search"
            icon-pack="feather"
            v-model="dataTableParams.search"
            @keyup="handleSearch"
            class="search-input w-full"
          />
        </vs-col>
      </vs-row>
      <!-- <div v-for="(tutorial, indextr) in tutorials">
                name:{{ tutorials[indextr].name }}
                videoLink:{{ tutorials[indextr].videoLink }}
                name:{{ tutorials[indextr].length }}
      </div>-->
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="custom-row">
        <vs-col
          vs-xs="12"
          vs-sm="6"
          vs-lg="4"
          class="custom-col"
          v-for="(tutorial, index) in tutorials"
          :key="index"
        >
          <div class="tutorial-wrap" @click="playVideo(tutorial)">
            <div class="video-image">
              <img v-if="tutorial.image" :src="tutorial.image" alt />
              <img
                v-else
                :src="$defaultVideoImage"
                alt
              />
            </div>
            <h3 class="h5 mb-1">
              <a href="#">{{ tutorial.name }}</a>
            </h3>
            <span>{{ tutorial.length }}</span>
          </div>
        </vs-col>
      </vs-row>

      <div class="m-2">
        <!--<span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>-->
        <div class="pagination-div float-none py-8" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>

    <vs-popup
      class="holamundo popup-example popup-large"
      title="Tutorials"
      :active.sync="popupActive"
            :fullscreen = "true"

      :button-close-hidden = "true"
    >
      <button @click="closePopup" style="float:right;" class="vs-button vs-button-primary" >X</button>
      <div id="video-player">
        <iframe
          id="video-frame"
          width="100%"
          height="700px"
          allow="autoplay; fullscreen"
          frameborder="0"
          :src="videoSrc"
        ></iframe>
        <!--<iframe
            src="//player.vimeo.com/video/108018156?autoplay=1"
        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      isMounted: false,
      videoSrc: "",
      popupActive: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        dir: "asc",
        limit: 6,
        page: 1,
      },
      tutorials: [],
      serverResponded: false,
    };
  },
  methods: {
    ...mapActions("tutorial", ["fetchAllTutorials"]),

    playVideo(tutorial) {
      //this.videoSrc = "https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1";
      this.videoSrc = tutorial.videoLink + "?autoplay=1";
      this.popupActive = true;
    },
    closePopup() {
      this.videoSrc = '';
      this.popupActive = false;
    },
    handleSearch() {
      this.dataTableParams.page = 1;
      //this.dataTableParams.search = this.search;
      this.getTutorials();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getTutorials();
    },

    getTutorials() {
      this.fetchAllTutorials(this.dataTableParams).then((res) => {
        this.tutorials = res.data.data.docs;

        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
      });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getTutorials();
      }
    },
  },
  created() {
    this.getTutorials();
  },
};
</script>
<style lang="scss">
.vs-dropdown--menu {
  ul {
    li {
      position: relative;
      z-index: 11;
    }
  }
}

.search-input {
  margin-bottom: 40px;
}
.custom-row {
  margin: 0;
  .custom-col {
    padding: 0 20px;
  }
  @media (min-width: 1480px) {
    margin: 0 -20px;
    .custom-col {
      padding: 0 20px;
    }
  }
  @media (min-width: 1480px) {
    margin: 0 -30px;
    .custom-col {
      padding: 0 30px;
    }
  }
  @media (min-width: 1640px) {
    margin: 0 -50px;
    .custom-col {
      padding: 0 50px;
    }
  }
}

.tutorial-wrap {
  margin-bottom: 40px;
  .h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 7px 0 10px;
    a {
      color: #2c2c2c;
    }
  }
}

.video-image {
  width: 100%;
  height: 230px;
  position: relative;
  cursor: pointer;
  @media (min-width: 1200px) {
    height: 280px;
  }
  @media (min-width: 1480px) {
    height: 320px;
  }
  &:before {
    content: "\f04b";
    background: rgba(96, 96, 96, 0.54);
    color: #ffffff;
    font-family: "FontAwesome";
    font-size: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.vs-popup {
  background: #000000 !important;
  height: 700px;
  .vs-popup--content {
    max-height: none;
    height: 100%;
    .pt-5 {
      height: 100%;
    }
  }
  iframe {
    height: 100% !important;
  }
  .vs-popup--close {
    color: #ffffff !important;
  }
}
.con-vs-popup.fullscreen .vs-popup{
    background: transparent !important;
    width: 100% !important;
    margin: 0px !important;
    max-width: none!important;
    max-height: none!important;
}
.con-vs-popup.fullscreen .vs-popup .vs-popup--content{
  max-width: 50%;
  max-height: 80vh;
  margin: 0 auto;
  // padding: 2% 0;
}
.con-vs-popup.fullscreen .vs-popup .vs-popup--content #video-player{
  padding-top: 60px;
  height: 100%;
}
</style>
